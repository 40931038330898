(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/assets/javascripts/pages/page-my-bets.js') >= 0) return;  svs.modules.push('/modules/oddset/assets/javascripts/pages/page-my-bets.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useMemo
} = React;
const {
  useKambiToken
} = svs.components.oddset.kambiAuthentication;
const {
  useShapeEnv
} = svs.components.oddset.shapeEnv;
const {
  updateBalance
} = svs.oddset.components.common.utils;
const {
  ShapeComponentConfigTypes
} = svs.oddset.components.common.constants;
const {
  ShapeGamesClient
} = svs.oddset.components.shape_games_client;
const sbLogger = new svs.core.log.Logger('modules:oddset:page-my-bets-ShapeClient');
const MyBetsPage = () => {
  const {
    token,
    hashedPunterId
  } = useKambiToken();
  const {
    shapeEnv
  } = useShapeEnv();

  const myBetsConfig = useMemo(() => {
    return {
      props: {
        providerToken: token ? token : '',
        sessionToken: token ? '123' : '',
        hashedPunterId,
        apiEnvironment: shapeEnv
      },
      eventListeners: {
        onCashoutSuccess: data => {
          const finalData = _objectSpread(_objectSpread({}, data), {}, {
            productId: 'productId',
            productName: 'productName'
          });
          sbLogger.info('NOT IMPLEMENTED onCashoutSuccess', finalData);
        },
        refreshBalance: updateBalance
      }
    };
  }, [token, shapeEnv, hashedPunterId]);
  return React.createElement(ShapeGamesClient, {
    componentName: ShapeComponentConfigTypes.BET_HISTORY,
    shapeConfig: myBetsConfig,
    version: "2.0.17"
  });
};
setGlobal('svs.oddset.pages.MyBetsPage', MyBetsPage);

 })(window);